<script>
import store from "@/store";
import { toCurrency } from "@/utils/filters";
import { propOr, find, pathOr } from "ramda";
import Defaults from "@/utils/enums/defaults";

export default {
  name: "Money",
  functional: true,
  props: {
    tag: {
      type: String,
      default: "span",
    },
    amount: {
      type: Number,
      default: 0,
    },
    businessId: {
      type: String,
      default: null,
    },
    hideSymbol: {
      type: Boolean,
      default: false,
    },
  },
  render(h, { data, props }) {
    const amount = Math.round(props.amount) || 0;
    const businesses = store.getters["businesses/getData"] ?? [];
    const business = find((obj) => obj.id === props.businessId, businesses);
    const code = pathOr(Defaults.CURRENCY, ["currency", "code"], business);
    const locale = propOr(Defaults.LANGUAGE, "language", business);

    const formattedAmount = toCurrency(amount, {
      format: props.hideSymbol ? "0,0" : "$0,0",
      code,
      locale,
    });

    return h(props.tag, data, formattedAmount);
  },
};
</script>
