<template>
  <span>{{ timeago }}</span>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export default {
  name: "TimeAgo",
  props: {
    datetime: {
      type: [String, Date, Number],
      default: () => new Date(),
    },
    refresh: {
      type: Number,
      default: 30,
    },
  },
  data: () => ({
    timeago: "",
    intervalId: null,
  }),
  watch: {
    datetime() {
      this.reloadTime();
    },
  },
  mounted() {
    this.reloadTime();
    this.intervalId = setInterval(this.reloadTime, this.refresh * 1000);
  },
  destroyed() {
    if (this.intervalId) clearInterval(this.intervalId);
  },
  methods: {
    reloadTime() {
      this.timeago = this.readableDate(new Date(this.datetime));
    },
    readableDate(date) {
      const diff = Math.round((new Date().getTime() - date) / 1000);
      const minute = 60,
        hour = minute * 60,
        day = hour * 24,
        week = day * 7;

      let readDate;

      if (diff < 30) {
        readDate = "just now";
      } else if (diff < minute) {
        readDate = diff + " seconds ago";
      } else if (diff < 2 * minute) {
        readDate = "a minute ago.";
      } else if (diff < hour) {
        readDate = Math.floor(diff / minute) + " minutes ago";
      } else if (Math.floor(diff / hour) === 1) {
        readDate = "1 hour ago";
      } else if (diff < day) {
        readDate = Math.floor(diff / hour) + " hours ago";
      } else if (diff < day * 2) {
        readDate = "yesterday";
      } else if (diff < day * 7 && diff > day * 2) {
        readDate = Math.floor(diff / day) + " days ago";
      } else if (diff > week && diff < week * 4) {
        if (Math.floor(diff / week) === 1) {
          readDate = "a week ago";
        } else {
          readDate = Math.floor(diff / week) + " weeks ago";
        }
      } else {
        readDate = dayjs(this.datetime).format("MMM DD, YYYY - h:mm a");
      }

      return readDate;
    },
  },
};
</script>
