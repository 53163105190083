var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltipCofig),expression:"tooltipCofig"}],class:[
    'request-delivery',
    { 'request-delivery--loading': _vm.isLoading },
    { 'request-delivery--is-ctf-careem': _vm.isCtfCareem },
    {
      'request-delivery--disabled':
        _vm.isDisabledByCardOnDelivery || !_vm.hasDeliveryCoverage,
    } ],attrs:{"data-test":"request-delivery"},on:{"click":function($event){$event.preventDefault();return _vm.confirmRequest.apply(null, arguments)}}},[(_vm.isLoading)?_c('span',{staticClass:"request-delivery__loading"}):_vm._e(),(_vm.logoPartner)?_c('img',{staticClass:"request-delivery__logo",attrs:{"src":_vm.logoPartner,"alt":_vm.partner}}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.label))])])}
var staticRenderFns = []

export { render, staticRenderFns }