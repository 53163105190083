<template>
  <div class="orderCard__header px-3">
    <strong> #{{ order.uid }}</strong>
    <TimeAgo class="mx-2" :datetime="order.created_at" />
    <span class="badge badge-lg p-2 f-size-11" :class="statusBadge">
      {{ order.status | cfStatusBeautify(order.ordering_service) }}
    </span>
  </div>
</template>

<script>
import TimeAgo from "@/components/helpers/TimeAgo.vue";
import { toKebabCase } from "ramda-extension";

export default {
  components: {
    TimeAgo,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  computed: {
    statusBadge() {
      return `badge-${toKebabCase(this.order.status)}`;
    },
  },
};
</script>
