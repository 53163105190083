<template>
  <MenuDropdown position="rightBottom">
    <template #button>
      <span
        class="d-inline-block p-14 pb-1 text-primary text-decoration-none f-size-13 f-w-500"
      >
        {{ $t("label.help") }}
      </span>
    </template>
    <template #list>
      <p class="p-2 p_left_20 m-0 bold">
        {{ $t("label.help_with") }}
        #{{ orderUid }}
      </p>
      <ArticleLink tag="contact_customer" class="dropCustom__menu--item">
        {{ $t("label.contact_customer") }}
      </ArticleLink>
      <ArticleLink
        tag="driver_late_pickup_order"
        class="dropCustom__menu--item"
      >
        {{ $t("message.driver_late_pickup_order") }}
      </ArticleLink>
      <a
        target="_black"
        class="dropCustom__menu--item"
        :href="'https://chatfood.typeform.com/to/r8tRBB?orderuid=' + orderUid"
      >
        {{ $t("label.refund_order") }}
      </a>
      <ArticleLink tag="cancel_order" class="dropCustom__menu--item">
        {{ $t("label.cancel_order") }}
      </ArticleLink>
    </template>
  </MenuDropdown>
</template>

<script>
import ArticleLink from "@/components/helpers/ArticleLink.vue";
import MenuDropdown from "@/components/helpers/MenuDropdown.vue";

export default {
  components: {
    ArticleLink,
    MenuDropdown,
  },
  props: {
    orderUid: {
      type: String,
      required: true,
    },
  },
};
</script>
