import Enumeration from "./enumeration";

/**
 * List of delivery partners
 *
 * @returns {Enumeration<statuses>}
 */
export const deliveryPartners = new Enumeration({
  quiqup: "Quiqup",
  oneclick: "One Click",
  careem_box: "Careem",
  swftbox: "swftbox",
  jeebly: "Jeebly",
  yallow: "Yallow",
  flick: "Flick",
  stuart: "Stuart",
  "ctf-shipox": "Shipox",
  "ctf-innablink": "Innablink",
  "ctf-hey-karry": "Hey Karry",
  "ctf-talabat": "Talabat",
  "ctf-careem": "Careem",
});

/**
 * List of delivery partners dash
 *
 * @returns {Enumeration<statuses>}
 */
export const deliveryPartnersDash = new Enumeration({
  quiqup: "Quiqup Dashboard",
  oneclick: "One Click Dashboard",
});

/**
 * List of delivery partners dash links
 *
 * @returns {Enumeration<statuses>}
 */
export const partnerDashLink = new Enumeration({
  quiqup: process.env.VUE_APP_QUIQDASH_LINK,
  oneclick: process.env.VUE_APP_ONECLICK_LINK,
});
