




























import PrinterSVG from "@/assets/svg/icons/printer.svg";
import { defineComponent, ref } from "@vue/composition-api";
import { report } from "@chatfood/bug-reporter";
import { generalErrorToast } from "@/v2/util/general-error-toast";

class PrintReceiptError extends Error {
  constructor(message: string, ...params: any[]) {
    super(...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, PrintReceiptError);
    }

    this.name = "PrintReceiptError";
    this.message = message;
  }
}

export default defineComponent({
  components: {
    PrinterSVG,
  },
  props: {
    orderId: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const printable = ref<HTMLIFrameElement>();

    const getReceiptContent = async (): Promise<string> => {
      try {
        const response = await fetch(
          `${process.env.VUE_APP_API_URL}/api/v1/receipt/${props.orderId}`
        );
        return response.text();
      } catch (e: any) {
        reportError(e);
      }

      return "";
    };

    const print = async () => {
      let html: string = "";

      html = await getReceiptContent();

      if (!html.length) {
        return;
      }

      try {
        const iframe = printable.value!;
        const iframedoc = iframe.contentWindow?.document;

        iframedoc?.open();
        iframedoc?.write(html);
        iframedoc?.close();

        iframe.contentWindow?.print();
      } catch (e: any) {
        reportError(e);
      }
    };

    const reportError = (error: any) => {
      report(new PrintReceiptError(error?.message ?? error), {
        context: {
          "original error": error,
        },
        tags: {
          "order.id": props.orderId,
        },
      });

      generalErrorToast();
    };

    return {
      print,
      printable,
    };
  },
});
