<template>
  <button
    v-if="$can(getPermissionByAction(action))"
    :class="className"
    :disabled="disabled"
    type="button"
    data-test="single-button"
    @click.prevent="dispatchAction(action)"
  >
    <template v-if="!loading">
      {{ label }}
    </template>
    <template v-else>
      <SpinnerIcon class="spin-active w-20" />
    </template>
  </button>
</template>

<script>
import SpinnerIcon from "@/assets/svg/icons/spinner.svg";

export default {
  components: {
    SpinnerIcon,
  },
  props: {
    label: {
      type: String,
      default: "More",
    },
    className: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    action: {
      type: String,
      default: "",
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getPermissionByAction(action) {
      switch (action) {
        case "acceptOrder":
          return "ACCEPT_ORDER";
        case "scheduleOrder":
          return "SCHEDULE_ORDER";
        case "markAsReady":
          return "MARK_ORDER_AS_READY";
        case "completeOrder":
          return "COMPLETE_ORDER";
        case "closeOrder":
          return "CLOSE_ORDER";
        case "rejectOrder":
          return "REJECT_ORDER";
        case "cancelOrder":
          return "CANCEL_ORDER";
        default:
          return "";
      }
    },
    dispatchAction(action) {
      this.$emit("actionDispatched", action);
    },
  },
};
</script>

<style scoped>
.spin-active {
  animation-name: spin;
  animation-duration: 2000ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
