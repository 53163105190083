





























import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, ref, computed } from "@vue/composition-api";
import { AtomText, AtomTextTypeEnum } from "@/v2/new-design-system";
import LoadingZone from "@/components/helpers/LoadingZone.vue";
import Accept from "./Accept.vue";
import Delivery from "./Delivery.vue";
import { t } from "@/i18n";

const css = bemBuilder("accept-index");

export default defineComponent({
  name: "AcceptIndex",
  components: {
    AtomText,
    Accept,
    Delivery,
    LoadingZone,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    deliveryServices: {
      type: Array,
      required: true,
    },
    serviceSettings: {
      type: Object,
      required: true,
    },
    printerSettings: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const loading = ref(false);
    const showAcceptPanel = ref(true);

    const titleLabel = computed(() => {
      return showAcceptPanel.value
        ? props.order.status === "scheduled"
          ? t("label.move_to_preparation")
          : t("label.accept_order")
        : t("c.order.how_to_deliver_title");
    });

    return {
      css,
      loading,
      titleLabel,
      showAcceptPanel,
      AtomTextTypeEnum,
    };
  },
});
