<template>
  <div>
    <span class="orderCard__details--uber-eats badge badge-lg">
      <span class="uber">Uber</span> <span class="eats">Eats</span>
    </span>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.orderCard__details--uber-eats {
  background-color: #000000;
}
.orderCard__details--uber-eats .uber {
  color: #ffffff;
  margin-right: 4px;
}
.orderCard__details--uber-eats .eats {
  color: #71bf43;
}
</style>
