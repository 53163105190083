<template>
  <div class="loading-zone" :style="loading ? `min-height: ${minHeight};` : ''">
    <template v-if="domVisibility">
      <div v-if="loading" class="loading-zone__loader" data-test="loading">
        <div><LoadingSVG class="w-40" /></div>
        <div v-if="message" class="loading-zone__message" data-test="message">
          {{ message }}
        </div>
      </div>
      <slot v-show="!loading" />
    </template>
    <template v-else>
      <div v-if="loading" class="loading-zone__loader" data-test="loading">
        <div><LoadingSVG class="w-40" /></div>
        <div v-if="message" class="loading-zone__message" data-test="message">
          {{ message }}
        </div>
      </div>
      <slot v-else />
    </template>
  </div>
</template>

<script>
import LoadingSVG from "@/assets/svg/icons/loading.svg";

export default {
  name: "LoadingZone",
  components: {
    LoadingSVG,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    message: {
      type: String,
      default: null,
    },
    domVisibility: {
      type: Boolean,
      default: true,
    },
    minHeight: {
      type: String,
      default: "300px",
    },
  },
};
</script>

<style lang="scss" scoped>
.loading-zone {
  position: initial;

  &__message {
    text-align: center;
    padding: 0 1.5rem;
    margin-top: 1rem;
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99;
    width: 100%;
    height: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    svg {
      margin: auto;
    }
  }
}
</style>
