<template>
  <div
    :class="[
      css(),
      {
        [css('', 'is-pending')]: isPending,
        [css('', 'has-failed')]: hasFailed,
        [css('', 'is-ctf-careem')]: isCtfCareem,
      },
    ]"
  >
    <template v-if="isPending">
      <AtomText :color="AtomTextColorEnum.CANARY" data-test="pending">
        {{ t("c.order.delivery_pending", [deliveryPartner]) }}
      </AtomText>
    </template>
    <template v-else-if="hasFailed">
      <AtomText :color="AtomTextColorEnum.CORAL" data-test="has-failed">
        {{ hasFailedLabel }}
      </AtomText>
    </template>
    <template v-else>
      <AtomText
        :class="css('title')"
        data-test="assigned-to"
        :type="AtomTextTypeEnum.BODY_BOLD"
      >
        {{
          t("c.order.delivery_job_id", {
            deliveryService: deliveryPartner,
            jobId: deliveryJobId,
          })
        }}
      </AtomText>
      <div :class="css('details')">
        <div v-if="delivery.driver" data-test="driver">
          <AtomText
            data-test="driver-name"
            :class="css('details-label')"
            :type="AtomTextTypeEnum.BODY_BOLD"
          >
            {{ t("c.order.capitan") }}: {{ delivery.driver.name }}
          </AtomText>
          <a
            v-if="delivery.driver.phone_number"
            data-test="driver-phone"
            :class="css('details-phone')"
            :href="`tel:${delivery.driver.phone_number}`"
          >
            {{ delivery.driver.phone_number }}
          </a>
        </div>
        <div v-if="delivery.pickup_estimated_at">
          <AtomText
            :class="css('details-label')"
            :type="AtomTextTypeEnum.BODY_BOLD"
          >
            {{ t("label.pickup_estimated") }}:
          </AtomText>
          <AtomText data-test="pickup-estimated">
            {{ delivery.pickup_estimated_at | dateCalendar }}
          </AtomText>
        </div>
        <div
          v-if="displayLink && orderTrackingLink && orderTrackingLink !== '/'"
          data-test="tracking"
        >
          <a
            target="_blank"
            :class="css('link')"
            rel="noopener noreferrer"
            :href="orderTrackingLink"
          >
            {{ t("c.order.view_on_map") }}
          </a>
        </div>
      </div>
      <AtomText
        v-if="hasCallCenter"
        :class="css('support')"
        :color="AtomTextColorEnum.RAINY"
      >
        <i18n :path="hasCallCenter.label">
          <template #phone>
            <a
              :class="css('support-link')"
              :href="`tel:${hasCallCenter.number}`"
            >
              {{ hasCallCenter.number }}
            </a>
          </template>
        </i18n>
      </AtomText>
    </template>
  </div>
</template>

<script>
import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, computed } from "@vue/composition-api";
import { dateCalendar } from "@/utils/filters";
import {
  deliveryPartners,
  deliveryPartnersDash,
  partnerDashLink,
} from "@/utils/enums/delivery-partners";
import { DeliveryJobState, DeliveryPartners } from "../../enums";
import {
  AtomText,
  AtomTextColorEnum,
  AtomTextTypeEnum,
} from "@/v2/new-design-system";
import { t } from "@/i18n";

const css = bemBuilder("delivery-job");

export default defineComponent({
  name: "DeliveryJob",
  filters: {
    dateCalendar,
  },
  components: {
    AtomText,
  },
  props: {
    delivery: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const displayLink = computed(() => {
      return [
        DeliveryPartners.CAREEM,
        DeliveryPartners.QUIQUP,
        DeliveryPartners.ONECLICK,
      ].includes(props.delivery.service_name);
    });

    const isPending = computed(() => {
      return props.delivery.state === DeliveryJobState.PENDING;
    });

    const isCtfCareem = computed(() => {
      return props.delivery.service_name === DeliveryPartners.CAREEM;
    });

    const isTalabat = computed(() => {
      return props.delivery.service_name === DeliveryPartners.TALABAT;
    });

    const hasFailed = computed(() => {
      return [DeliveryJobState.FAILED, DeliveryJobState.CANCELLED].includes(
        props.delivery.state
      );
    });

    const notAcceptingNewJobs = computed(() => {
      return props.delivery.reason_failed === "not_accepting_new_jobs";
    });

    const hasCreated = computed(() => {
      return props.delivery.state === DeliveryJobState.CREATED;
    });

    const serviceName = computed(() => {
      return props.delivery.service_name;
    });

    const deliveryReasonFailed = computed(() => {
      return props.delivery.reason_failed;
    });

    const deliveryPartner = computed(() => {
      return deliveryPartners[serviceName.value];
    });

    const deliveryPartnerDash = computed(() => {
      return deliveryPartnersDash[serviceName.value];
    });

    const partnerDashboardLink = computed(() => {
      return partnerDashLink[serviceName.value];
    });

    const hasFailedLabel = computed(() => {
      const notAcceptingNewJobsLabel = notAcceptingNewJobs.value
        ? t("c.order.delivery_failed_not_accepting_new_jobs", [
            deliveryPartner.value,
          ])
        : t("c.order.delivery_failed", [deliveryPartner.value]);

      return deliveryReasonFailed.value
        ? t("c.order.delivery_failed_integration_partner", [
            deliveryPartner.value,
            deliveryReasonFailed.value,
          ])
        : notAcceptingNewJobsLabel;
    });

    const deliveryJobId = computed(() => {
      const jobId = props.delivery?.job_id ?? "";

      if (!isTalabat.value && jobId.includes("-")) {
        return jobId.split("-")[1];
      }

      return props.delivery?.job_id;
    });

    const orderTrackingLink = computed(() => {
      if (isCtfCareem.value) {
        return props.delivery?.tracking_url ?? "";
      }

      return `${partnerDashboardLink.value}/live/${props.delivery.job_id}`;
    });

    const hasCallCenter = computed(() => {
      if (isCtfCareem.value) {
        return {
          label: t("c.order.delivery_partners.careem_support"),
          number: "+971 4 279 0334",
        };
      }

      if (isTalabat.value) {
        return {
          label: t("c.order.delivery_partners.talabat_support"),
          number: "045219807",
        };
      }

      return null;
    });

    return {
      t,
      css,
      displayLink,
      isPending,
      hasCreated,
      isCtfCareem,
      hasFailed,
      serviceName,
      hasFailedLabel,
      notAcceptingNewJobs,
      deliveryReasonFailed,
      deliveryPartner,
      deliveryPartnerDash,
      partnerDashboardLink,
      deliveryJobId,
      orderTrackingLink,
      hasCallCenter,
      AtomTextColorEnum,
      AtomTextTypeEnum,
    };
  },
});
</script>

<style lang="scss" scoped>
$careemColor: rgb(72, 162, 63);

.delivery-job {
  $self: &;

  padding: var(--space-14);
  border-radius: var(--border-radius-1);
  margin: var(--space-3) 0 var(--space-21);
  border: solid 1px var(--color-cloudy);
  background-color: var(--color-powder);

  &--is-pending {
    border-color: var(--color-canary);
    background-color: var(--color-lemon-sorbet);
  }

  &--has-failed {
    border-color: var(--color-coral);
    background-color: var(--color-salmon);
  }

  &__details {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
    column-gap: var(--space-14);

    > div {
      margin-top: var(--space-7);
    }

    &-label {
      display: block;
    }

    &-phone {
      color: var(--color-midnight);
    }
  }

  &__link {
    display: inline-block;
    line-height: 21px;
    color: var(--color-midnight);
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-semi-bold);
    border-radius: var(--border-radius-2);
    padding: var(--space-7) var(--space-21);
    background-color: var(--color-pure-white);

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }

  &__support {
    display: block;
    font-size: var(--font-size-small);
    margin-top: var(--space-14);

    &-link {
      color: var(--color-rainy);
    }
  }

  &--is-ctf-careem:not(&--is-pending):not(&--has-failed) {
    border-color: rgba($careemColor, 0.2);
    background-color: rgba($careemColor, 0.1);

    #{ $self }__title {
      color: $careemColor;
    }

    #{ $self }__link {
      color: $careemColor;
    }
  }
}
</style>
