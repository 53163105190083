<template>
  <div class="orderCard__charges text-right">
    <div class="mb-2">
      {{ $t("label.subtotal") }}:
      <Money
        :amount="order.subtotal"
        :business-id="businessId"
        :hide-symbol="true"
        class="orderCard__charges--value"
      />
    </div>
    <div v-if="order.tax" class="mb-2">
      {{ $t("label.vat") }}
      <small>({{ taxRate }}%)</small>:
      <Money
        :amount="order.tax"
        :business-id="businessId"
        :hide-symbol="true"
        class="orderCard__charges--value"
      />
    </div>
    <div v-if="shouldShowDeliveryFee" class="mb-2" data-test="delivery-fee">
      {{ $t("label.delivery_fee") }}:
      <Money
        :amount="order.delivery_fee"
        :business-id="businessId"
        :hide-symbol="true"
        class="orderCard__charges--value"
      />
    </div>
    <div v-if="tip" class="mb-2" data-test="delivery-fee">
      {{ $t("label.tips") }}:
      <Money
        :amount="tip"
        :business-id="businessId"
        :hide-symbol="true"
        class="orderCard__charges--value"
      />
    </div>
    <template v-if="serviceChargesFiltered.length">
      <div
        v-for="serviceCharge in serviceChargesFiltered"
        :key="serviceCharge.name"
        class="mb-2"
        data-test="service-charges"
      >
        {{ $t("label.service_charge") }}
        <small>({{ serviceCharge.charge_value }}%)</small>:
        <Money
          :amount="serviceCharge.charged_amount"
          :business-id="businessId"
          :hide-symbol="true"
          class="orderCard__charges--value"
        />
      </div>
    </template>
    <div v-for="discount in order.discounts" :key="discount.id" class="mb-2">
      {{ $t("label.discount") }}
      <small v-if="discount.discount_type == 'PERCENTAGE'">
        ({{ discount.value }}% - {{ discount.name }})
      </small>
      <small v-else>({{ discount.name }})</small>:
      <Money
        :amount="-1 * discount.amount"
        :business-id="businessId"
        :hide-symbol="true"
        class="text-danger orderCard__charges--value"
      />
    </div>
    <h5 class="m-0">
      {{ $t("label.total") }}:
      <Money
        :amount="total"
        :business-id="businessId"
        class="text-primary orderCard__charges--value"
      />
    </h5>
  </div>
</template>

<script>
import Money from "@/components/helpers/Money.vue";

export default {
  components: {
    Money,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
    taxRate: {
      type: Number,
      default: null,
    },
    tip: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    total() {
      return this.order.total + this.tip;
    },
    shouldShowDeliveryFee() {
      if (
        this.order.ordering_service === "delivery" &&
        this.order.delivery_fee > 0
      ) {
        return true;
      }

      return false;
    },
    serviceChargesFiltered() {
      return this.order.service_charges
        ? this.order.service_charges.filter((charge) => charge.charge_value > 0)
        : [];
    },
  },
};
</script>
