<template>
  <div>
    <span class="orderCard__details--zomato">
      <img src="@/assets/img/logo-zomato.png" />
    </span>
    <span
      v-tooltip="{
        autoHide: false,
        trigger: 'click hover',
        content: `${$t('label.helpline')}: +971 4 550 9227`,
      }"
      class="ml-2"
    >
      <QuestionCircleIcon class="w-15" />
    </span>
  </div>
</template>

<script>
import QuestionCircleIcon from "@/assets/svg/icons/question-circle.svg";

export default {
  components: {
    QuestionCircleIcon,
  },
};
</script>
