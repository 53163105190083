<template>
  <div>
    <div class="preparation" data-test="preparation-minutes">
      <h5 v-t="'label.prep_time'" class="text-center" />
      <cf-range
        type="preparation_minutes"
        :service="order.ordering_service"
        :start-time="serviceSettings.preparationMinutes"
        :max="maxPrepTime"
        @update="increasePreparationIn = $event"
      />
    </div>
    <div v-if="isForDelivery" data-test="completion-minutes">
      <hr class="my-4" />
      <div class="delivery">
        <h5 v-t="'label.delivery_time'" class="text-center" />
        <cf-range
          type="estimated_completion_minutes"
          :start-time="serviceSettings.estimatedCompletionMinutes"
          :service="order.ordering_service"
          @update="increaseCompletionIn = $event"
        />
      </div>
    </div>
    <div class="d-flex justify-content-between mt-5">
      <button
        v-t="'label.close'"
        type="button"
        class="btn btn-light"
        @click.prevent="closeModal"
      />
      <button
        v-t="
          order.status === 'scheduled'
            ? 'label.move_to_preparation'
            : 'label.accept'
        "
        type="button"
        class="btn btn-primary"
        data-test="accept-button"
        :disabled="isLoading"
        @click="acceptOrder()"
      />
    </div>
  </div>
</template>

<script>
import CfRange from "./RangeField";
import { length, isNil } from "ramda";
import { mapActions } from "vuex";
import { orderCardTracking, OrderCardEventEnum } from "../../tracking";
import { Toast } from "@/design-system";

export default {
  components: {
    CfRange,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    deliveryServices: {
      type: Array,
      required: true,
    },
    serviceSettings: {
      type: Object,
      required: true,
    },
    printerSettings: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    increasePreparationIn: 0,
    increaseCompletionIn: 0,
    isLoading: false,
  }),
  computed: {
    shouldPrintReceiptOnAccept() {
      return !isNil(this.printerSettings);
    },
    hasDeliveryServices() {
      return length(this.deliveryServices);
    },
    isForDelivery() {
      return this.order.ordering_service === "delivery";
    },
    maxPrepTime() {
      return this.order.channel === "zomato" ? 30 : null;
    },
    estimatedCompletionMinutes() {
      return this.isForDelivery
        ? this.increaseCompletionIn
        : this.increasePreparationIn;
    },
    shouldRequestDelivery() {
      return (
        this.isForDelivery &&
        this.order.is_delivery_enabled &&
        this.hasDeliveryServices
      );
    },
  },
  methods: {
    ...mapActions({
      accept: "orderCard/acceptOrder",
    }),
    setIsLoading(loading) {
      this.isLoading = loading;
      this.$emit("setLoading", loading);
    },
    async acceptOrder() {
      this.setIsLoading(true);

      try {
        await this.accept({
          preparation_minutes: this.increasePreparationIn,
          estimated_completion_minutes: this.estimatedCompletionMinutes,
        });

        orderCardTracking(this, OrderCardEventEnum.ACCEPTED, this.order);

        this.orderAccepted();
      } catch (err) {
        new Toast().create({
          type: "error",
          text: this.$t("message.something_went_wrong"),
        });
      }

      this.setIsLoading(false);
    },
    closeModal() {
      this.$emit("close");
    },
    async printOrder() {
      const pdfLink = `${process.env.VUE_APP_API_URL}/api/v1/receipt/${this.order.id}?format=pdf`;

      await fetch("https://api.printnode.com/printjobs", {
        body: JSON.stringify({
          title: `Order ID ${this.order.id}`,
          printerId: this.printerSettings.id,
          contentType: "pdf_uri",
          content: pdfLink,
          source: "Dashboard",
          expireAfter: 600,
          options: this.printerSettings.params,
        }),
        headers: {
          Authorization: `Basic ${btoa(this.printerSettings.apiKey)}`,
          "Content-Type": "application/json",
        },
        method: "POST",
      });
    },
    orderAccepted() {
      if (this.shouldPrintReceiptOnAccept) {
        this.printOrder();
      }

      if (this.shouldRequestDelivery) {
        this.$emit("showAcceptPanel", false);
        return;
      }

      this.closeModal();
    },
  },
};
</script>
