import {
  DeliveryPartners as Partners,
  PaymentMethods,
} from "@/modules/order-card/enums";

export const getDeliveryPartnersList = (
  deliveryServices: Array<Partners>,
  paymentMethod: PaymentMethods
): Array<Partners> => {
  const isOfflineMethod = [
    PaymentMethods.CARD_DELIVERY,
    PaymentMethods.CASH_DELIVERY,
  ].includes(paymentMethod);

  return deliveryServices.filter((service) => {
    if (isOfflineMethod) {
      return ![Partners.TALABAT].includes(service);
    }

    return true;
  });
};
