import { http } from "@/v2/repo/http";
import { IRepoErrors } from "../../errors";
import { DeliveryPartners } from "@/modules/order-card/enums";

export interface IValidateDeliveryCoverageBody {
  partner: DeliveryPartners;
  coordinates: {
    lat: number;
    lng: number;
  };
}

export class ValidateDeliveryCoverageError extends Error {
  errors: IRepoErrors;

  constructor(errors: IRepoErrors) {
    super("Error in repo: validateDeliveryCoverage");
    this.errors = errors;
    this.name = this.constructor.name;
  }
}

export async function validateDeliveryCoverage(
  request: IValidateDeliveryCoverageBody
): Promise<boolean> {
  try {
    const response = await http.post(
      "/api/v4/deliveries/validate-delivery-coverage",
      request
    );
    return response.data?.is_covered;
  } catch (e: any) {
    const error: IRepoErrors<null> = {};
    const isAxiosError = Boolean(e?.isAxiosError);

    if (!isAxiosError) {
      throw e;
    }

    throw new ValidateDeliveryCoverageError(error);
  }
}
