<template>
  <LoadingZone :loading="loading" class="h-100">
    <div class="p-4">
      <div class="row mb-4">
        <div class="col-sm text-center text-sm-left">
          <h5 v-t="'label.reject_order'" />
        </div>
        <div class="col-sm text-center text-sm-right">
          <h5>#{{ orderUid }}</h5>
        </div>
      </div>
      <h5 class="text-center mb-4">
        <strong v-t="'message.please_select_a_reason'" />
      </h5>
      <div class="d-flex" style="min-height: 130px">
        <button
          v-for="opt in options"
          :key="opt.key"
          type="button"
          class="btn btn-lg w-100 mx-1 lh-150 p-3 f-size-13"
          :class="reason === opt.key ? 'btn-dark' : 'btn-outline-dark'"
          @click.prevent="reason = opt.key"
        >
          {{ opt.label }}
        </button>
      </div>
      <div class="d-flex justify-content-between mt-5">
        <button
          v-t="'label.cancel'"
          type="button"
          class="btn btn-light"
          @click.prevent="$emit('close')"
        />
        <button
          v-t="'label.reject'"
          type="button"
          :disabled="loading"
          class="btn btn-danger"
          data-test-rejectBtn
          @click.prevent="rejectOrder"
        />
      </div>
    </div>
  </LoadingZone>
</template>

<script>
import LoadingZone from "@/components/helpers/LoadingZone.vue";
import { mapActions } from "vuex";
import { orderCardTracking, OrderCardEventEnum } from "../tracking";
import { Toast } from "@/design-system";

export default {
  components: {
    LoadingZone,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    orderUid: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    reason: null,
    loading: false,
    options: [],
  }),
  created() {
    this.options = [
      {
        key: "busy",
        label: this.$t("label.too_busy"),
      },
      {
        key: "out_delivery_area",
        label: this.$t("label.out_of_delivery_area"),
      },
      {
        key: "out_stock",
        label: this.$t("label.out_of_stock"),
      },
    ];
  },
  methods: {
    ...mapActions({
      reject: "orderCard/rejectOrder",
    }),
    async rejectOrder() {
      if (this.reason === null) {
        new Toast().create({
          type: "warning",
          text: this.$t("message.please_select_a_reason"),
        });
        return;
      }

      this.loading = true;

      try {
        await this.reject(this.reason);
        orderCardTracking(this, OrderCardEventEnum.REJECTED, this.order, {
          reason: this.reason,
        });
      } catch (err) {
        new Toast().create({
          type: "error",
          text: this.$t("message.something_went_wrong"),
        });
      } finally {
        this.$emit("close");
      }

      this.loading = false;
    },
  },
};
</script>
