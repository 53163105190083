<template>
  <div class="dropCustom d-flex">
    <a
      v-on-clickaway="hide"
      href="javascript:;"
      class="dropCustom__link"
      @click.prevent="isVisible = true"
    >
      <slot name="button" />
    </a>
    <div class="dropCustom__menu" :class="classList">
      <slot name="list" />
    </div>
  </div>
</template>

<script>
import { directive as onClickaway } from "vue-clickaway";

export default {
  directives: {
    onClickaway: onClickaway,
  },
  props: {
    position: {
      type: String,
      default: "leftBottom",
    },
  },
  data: () => ({
    isVisible: false,
  }),
  computed: {
    classList() {
      let classes = `dropCustom__menu--${this.position}`;

      if (this.isVisible) {
        classes += " dropCustom__menu--visible";
      }

      return classes;
    },
  },
  methods: {
    hide() {
      if (this.isVisible === true) this.isVisible = false;
    },
  },
};
</script>
