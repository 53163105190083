<template>
  <div>
    <div class="row">
      <div class="col-auto minw-55 text-right">{{ item.quantity }}x</div>
      <div class="col pl-0">{{ item.name }}</div>
      <div class="d-flex align-items-baseline">
        <AtomIcon
          v-if="showDiscountTag"
          name="tag"
          :size="10"
          color="var(--color-brand-primary-dark)"
        />
        <Money
          v-if="showPrice"
          tag="div"
          :amount="item.total_price"
          :business-id="businessId"
          :hide-symbol="true"
          class="text-right ml-2"
        />
      </div>
    </div>
    <div
      v-for="(child, index) in groupedChildren"
      :key="index"
      class="p_left_28 pb-2 f-size-13"
    >
      <p class="text-muted mt-2 mb-0 pl-3">{{ child[0].name }}</p>
      <Item
        v-for="(subItem, i) in child[0].items"
        :key="i"
        :item="subItem"
        :business-id="businessId"
        :show-price="false"
      />
    </div>
    <div v-if="item.notes" class="p_left_28 pb-2 f-size-13">
      <p class="text-muted mt-2 mb-0 pl-3">{{ $t("c.order.item_notes") }}</p>
      <p class="mb-0 pl-3">{{ item.notes }}</p>
    </div>
  </div>
</template>

<script>
import Money from "@/components/helpers/Money.vue";
import { propOr, groupBy } from "ramda";
import { AtomIcon } from "@/v2/design-system";

export default {
  name: "Item",
  components: {
    Money,
    AtomIcon,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    showDiscountTag: {
      type: Boolean,
      default: false,
    },
    showPrice: {
      type: Boolean,
      default: true,
    },
    businessId: {
      type: String,
      required: true,
    },
  },
  computed: {
    children() {
      return propOr([], "modifier_lists", this.item);
    },
    groupedChildren() {
      return groupBy((obj) => obj.name, this.children);
    },
  },
};
</script>
