<template>
  <button
    v-tooltip="tooltipCofig"
    :class="[
      'request-delivery',
      { 'request-delivery--loading': isLoading },
      { 'request-delivery--is-ctf-careem': isCtfCareem },
      {
        'request-delivery--disabled':
          isDisabledByCardOnDelivery || !hasDeliveryCoverage,
      },
    ]"
    data-test="request-delivery"
    @click.prevent="confirmRequest"
  >
    <span v-if="isLoading" class="request-delivery__loading"></span>
    <img
      v-if="logoPartner"
      class="request-delivery__logo"
      :src="logoPartner"
      :alt="partner"
    />
    <span>{{ label }}</span>
  </button>
</template>

<script>
import Orders from "@/api/orders";
import { confirmDialog } from "@/utils/helpers/dialogs";
import {
  deliveryPartners,
  deliveryPartnersDash,
} from "@/utils/enums/delivery-partners";
import { PaymentMethods } from "@/modules/order-card/enums";
import { validateDeliveryCoverage } from "@/v2/repo/order/validate-delivery-coverage";
import { watchFlag, unWatchFlag } from "@/v2/core/feature-flag";
import { Toast } from "@/design-system";
import { mapGetters } from "vuex";
import { report } from "@chatfood/bug-reporter";

export default {
  name: "RequestDelivery",
  props: {
    order: {
      type: Object,
      required: true,
    },
    partner: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["setLoadingState"],
  data: () => ({
    isLoading: false,
    hasDeliveryCoverage: true,
    validateCareemPolygon: false,
    careemPolygonFlag: "careem-polygon",
  }),
  computed: {
    ...mapGetters({
      outlets: "outlets/getData",
    }),
    logoPartner() {
      try {
        return require(`../../assets/partners/${this.partner}.png`);
      } catch {
        return null;
      }
    },
    isCtfCareem() {
      return this.partner === "ctf-careem";
    },
    isCareemNow() {
      return this.partner === "careem_box";
    },
    label() {
      return this.isCtfCareem
        ? this.$t("c.order.ctf_careem.request_driver")
        : this.deliveryPartner;
    },
    careemDesc() {
      return this.isDisabledByCardOnDelivery
        ? this.$t("c.order.ctf_careem.order_not_prepaid")
        : this.$t("c.order.ctf_careem.powered_careem");
    },
    isCardOnDelivery() {
      return this.order.payment_method === PaymentMethods.CARD_DELIVERY;
    },
    isCashOnDelivery() {
      return this.order.payment_method === PaymentMethods.CASH_DELIVERY;
    },
    isDisabledByCardOnDelivery() {
      return (
        this.isDisabled ||
        (this.isCareemNow && this.isCardOnDelivery) ||
        (this.isCtfCareem && (this.isCardOnDelivery || this.isCashOnDelivery))
      );
    },
    tooltipCofig() {
      if (this.isDisabledByCardOnDelivery) {
        return {
          trigger: "click hover focus",
          content: this.$t(
            this.isCtfCareem
              ? "message.partner_doesnt_accept_card_or_cash_on_delivery"
              : "message.partner_doesnt_accept_card_on_delivery",
            {
              partner: this.deliveryPartner,
            }
          ),
        };
      }

      if (!this.hasDeliveryCoverage) {
        return {
          trigger: "click hover focus",
          content: this.$t("message.partner_doesnt_cover_this_address", {
            partner: this.deliveryPartner,
          }),
        };
      }

      return null;
    },
    deliveryPartner() {
      return deliveryPartners[this.partner];
    },
    deliveryPartnerDash() {
      return deliveryPartnersDash[this.partner];
    },
    outlet() {
      return this.outlets.find((outlet) => outlet.id === this.order.outlet_id);
    },
  },
  watch: {
    validateCareemPolygon: {
      handler(value) {
        if (value && this.isCtfCareem && !this.isDisabledByCardOnDelivery) {
          this.validateDelivery();
        }
      },
      immediate: true,
    },
  },
  mounted() {
    watchFlag(this.careemPolygonFlag, this.updateFlag);
  },
  beforeUnmount() {
    unWatchFlag(this.careemPolygonFlag, this.updateFlag);
  },
  methods: {
    updateFlag(val) {
      this.validateCareemPolygon = val;
    },
    async validateDelivery() {
      try {
        let [hasOutletCoverage, hasOrderCoverage] = await Promise.all([
          validateDeliveryCoverage({
            partner: this.partner,
            coordinates: {
              lat: this.outlet.address.lat,
              lng: this.outlet.address.lng,
            },
          }),
          validateDeliveryCoverage({
            partner: this.partner,
            coordinates: {
              lat: this.order.address.lat,
              lng: this.order.address.lng,
            },
          }),
        ]);

        if (!hasOutletCoverage || !hasOrderCoverage) {
          this.hasDeliveryCoverage = false;
        }
      } catch (e) {
        report(e);
        this.hasDeliveryCoverage = false;
      }
    },
    async createDeliveryJob() {
      this.$modal.hide("dialog");
      this.$emit("setLoadingState", true);
      this.isLoading = true;
      try {
        await Orders.requestDelivery(this.order.id, this.partner);

        new Toast().create({
          type: "success",
          text: this.$t("message.driver_has_been_requested", {
            partner: this.deliveryPartner,
            partnerDash: this.deliveryPartnerDash,
          }),
        });
      } catch (error) {
        const errorMessage = this.getErrorMessage(error);

        new Toast().create({
          type: "error",
          text: this.$t(errorMessage),
        });
      } finally {
        this.isLoading = false;
        this.$emit("setLoadingState", false);
      }
    },
    confirmRequest() {
      if (
        this.isLoading ||
        !this.hasDeliveryCoverage ||
        this.isDisabledByCardOnDelivery
      )
        return;

      confirmDialog(this, this.createDeliveryJob, {
        title: this.$t("label.request_x_driver", [this.deliveryPartner]),
        customMessage: this.$t("message.confirm_request_x", {
          partner: this.deliveryPartner,
          order: this.order.uid,
        }),
      });
    },

    getErrorMessage(validationError) {
      const businessRuleError = validationError?.error?.code;

      const knownRules = [
        "dropoff_coordinate_is_required",
        "pickup_coordinate_is_required",
        "pending_delivery_job_already_exists",
        "delivery_integration_not_enabled",
        "address_is_invalid",
      ];

      if (!businessRuleError || !knownRules.includes(businessRuleError)) {
        return this.$t("message.partner_general_error", {
          partner: this.deliveryPartner,
          partnerDash: this.deliveryPartnerDash,
        });
      }

      const errorMessage = validationError?.error?.message;
      if (errorMessage && businessRuleError === "address_is_invalid") {
        return this.$t(`business_rules.${businessRuleError}`, {
          error: errorMessage,
        });
      }

      return this.$t(`business_rules.${businessRuleError}`);
    },
  },
};
</script>

<style lang="scss" scoped>
.request-delivery {
  $self: &;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: none;
  height: 35px;
  font-family: "Inter";
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  padding: var(--space-7);
  color: var(--color-midnight);
  border-radius: var(--border-radius-2);
  background-color: var(--color-cloudy);
  transition: all 0.2s ease-out;

  &:not(&--loading):not(&--disabled) {
    &:hover {
      color: var(--color-pure-white);
      background-color: var(--color-atlantic);
    }

    &:active {
      color: var(--color-pure-white);
      background-color: var(--color-midnight);
    }
  }

  &:disabled,
  &--disabled {
    cursor: no-drop;
    color: var(--color-rainy);

    #{ $self }__logo {
      opacity: 0.4;
    }
  }

  &--loading {
    color: transparent;
    cursor: default;

    #{ $self }__logo {
      opacity: 0;
    }
  }

  &__logo {
    display: inline-block;
    width: 21px;
    height: 21px;
    border-radius: 100%;
    margin-right: var(--space-7);
  }

  &__loading {
    width: 16px;
    height: 16px;
    border: 3px solid var(--color-rainy);
    border-radius: 23px;
    clip: rect(0px 23px 46px 6px);
    position: absolute;
    top: 50%;
    left: 50%;
    mask-image: -webkit-gradient(
      linear,
      0 0,
      0 100%,
      from(rgba(131, 140, 165, 0.5)),
      to(var(--color-rainy))
    );
    animation: loading 0.6s linear infinite;
    transform: translate(-50%, -50%);
  }

  @keyframes loading {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    50% {
      transform: translate(-50%, -50%) rotate(180deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }

  &--is-ctf-careem {
    color: var(--color-pure-white);
    background-color: #48a23f !important;

    &#{ $self }--disabled {
      color: rgba(255, 255, 255, 0.7);
    }

    &#{ $self }--loading {
      color: transparent;
    }

    #{ $self }__loading {
      border-color: var(--color-pure-white);
    }
  }
}
</style>
