export enum Channels {
  ZOMATO = "zomato",
  UBEREATS = "ubereats",
  TALABAT = "talabat",
}

export enum DeliveryPartners {
  QUIQUP = "quiqup",
  ONECLICK = "oneclick",
  CAREEM_BOX = "careem_box",
  SWFTBOX = "swftbox",
  JEEBLY = "jeebly",
  YALLOW = "yallow",
  FLICK = "flick",
  STUART = "stuart",
  SHIPOX = "ctf-shipox",
  INNABLINK = "ctf-innablink",
  HEY_KARRY = "ctf-hey-karry",
  TALABAT = "ctf-talabat",
  CAREEM = "ctf-careem",
}

export enum DeliveryJobState {
  FAILED = "FAILED",
  PENDING = "PENDING",
  CREATED = "CREATED",
  CANCELLED = "CANCELLED",
}

export enum PaymentMethods {
  CASH_DELIVERY = "cash_delivery",
  CASH_PICKUP = "cash_pickup",
  CARD_DELIVERY = "card_delivery",
  CARD_PICKUP = "card_pickup",
  CARD_ONLINE = "card_online",
  APPLE_PAY = "apple_pay",
  SKIPCASH = "skipcash",
}

export enum OrderingModes {
  DELIVERY = "delivery",
  PICKUP = "pickup",
  DINE_IN = "dine-in",
}

export enum RejectReasons {
  BUSY = "busy",
  OUT_DELIVERY_AREA = "out_delivery_area",
  NO_STOCK = "out_stock",
  TIMED_OUT = "timed_out",
}

export enum Statuses {
  PLACED = "placed",
  SCHEDULED = "scheduled",
  ACCEPTED = "accepted",
  COMPLETE = "complete",
  REJECTED = "rejected",
  CANCELLED = "cancelled",
  READY = "ready",
}
