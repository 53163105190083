import Enumeration from "./enumeration";

/** @type {String} Order statuses */
export const PLACED = "placed";
export const ACCEPTED = "accepted";
export const COMPLETE = "complete";
export const REJECTED = "rejected";
export const CANCELLED = "cancelled";
export const READY = "ready";
export const SCHEDULED = "scheduled";

export const defaultIncludes = new Enumeration({
  customer: "customer",
  delivery: "delivery",
  deliveryDriver: "delivery.driver",
  area: "address.area",
  lines: "lines",
  linesItem: "lines.item",
  linesModifier: "lines.modifiers.modifier",
  items: "items",
  itemsModifierLists: "items.modifierLists",
  discounts: "discounts",
  serviceCharges: "serviceCharges",
});

/**
 * List of order status
 *
 * @returns {Enumeration<statuses>}
 */
export const statuses = new Enumeration({
  placed: PLACED,
  accepted: ACCEPTED,
  complete: COMPLETE,
  rejected: REJECTED,
  cancelled: CANCELLED,
  ready: READY,
  scheduled: SCHEDULED,
});

/**
 * List of live statuses
 *
 * @returns {Enumeration<statuses>}
 */
export const liveStatuses = new Enumeration({
  placed: PLACED,
  accepted: ACCEPTED,
  ready: READY,
  scheduled: SCHEDULED,
});

/**
 * List of reject reasons
 *
 * @returns {Enumeration<reasons>}
 */
export const rejectReasons = new Enumeration({
  BUSY: "busy",
  OUT_DELIVERY_AREA: "out_delivery_area",
  NO_STOCK: "out_stock",
  TIMED_OUT: "timed_out",
});
