<template>
  <div>
    <div class="input-group range_minus">
      <div class="input-group-prepend" @click.prevent="subtract()">
        <div class="input-group-text">
          <MinusIcon />
        </div>
      </div>
      <input
        v-model="increaseIn"
        type="number"
        min="0"
        :max="max"
        class="form-control text-center"
        @focus="$event.target.select()"
      />
      <div class="input-group-append" @click.prevent="increase()">
        <div class="input-group-text">
          <PlusIcon />
        </div>
      </div>
    </div>
    <p v-t="'label.minutes'" class="d-block text-center mt-1" />
  </div>
</template>

<script>
import PlusIcon from "@/assets/svg/icons/plus.svg";
import MinusIcon from "@/assets/svg/icons/minus.svg";

export default {
  components: {
    PlusIcon,
    MinusIcon,
  },
  props: {
    startTime: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      default: 999,
    },
  },
  data: () => ({
    increaseIn: 0,
    timeInterval: 1,
  }),
  watch: {
    increaseIn(val) {
      const value = parseInt(val, 10);

      if (this.max && value > this.max) {
        this.increaseIn = this.max;
        return;
      }

      if (isNaN(value)) {
        this.increaseIn = 0;
        return;
      }

      this.$emit("update", parseInt(val));
    },
  },
  mounted() {
    this.setIncreaseIn(this.startTime);
    this.timeInterval = parseInt(
      process.env.VUE_APP_ACCEPT_ORDER_TIME_INTERVAL
    );
  },
  methods: {
    increase() {
      const current = parseInt(this.increaseIn) + this.timeInterval;
      this.setIncreaseIn(current < 0 ? 0 : current);
    },
    subtract() {
      const current = parseInt(this.increaseIn) - this.timeInterval;
      this.setIncreaseIn(current < 0 ? 0 : current);
    },
    setIncreaseIn(val) {
      this.increaseIn = this.max && val > this.max ? this.max : val;
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
