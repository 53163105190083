export enum OrderCardEventEnum {
  ACCEPTED = "Order accepted",
  REJECTED = "Order rejected",
  READY = "Order ready",
  COMPLETED = "Order completed",
  CANCLED = "Order canceled",
}

export function orderCardTracking(
  context: any,
  eventName: OrderCardEventEnum,
  order: any,
  additionalData: Record<string, any> = {}
): void {
  const userData = context.$store.getters["auth/getData"];

  const businessesData = context.$store.getters["businesses/getData"];
  const outletsData = context.$store.getters["outlets/getData"];
  const currentBusiness = businessesData.find(
    (business: any) => business.id === order.business_id
  );
  const currentOutlet = outletsData.find(
    (outlet: any) => outlet.id === order.outlet_id
  );

  // @ts-ignore
  context.$analytics.trackEvent(eventName, {
    id: userData.id,
    business_id: currentBusiness.id,
    business_name: currentBusiness.name,
    outlet_id: order.outlet_id,
    outlet_name: currentOutlet.name,
    order_id: order.id,
    ordering_service: order.ordering_service,
    ...additionalData,
  });
}
