var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.tooltipCofig),expression:"tooltipCofig"}],class:[
    'delivery-partner',
    { 'delivery-partner--loading': _vm.isLoading },
    { 'delivery-partner--is-ctf-careem': _vm.isCtfCareem },
    {
      'delivery-partner--disabled':
        _vm.isDisabledByCardOnDelivery || !_vm.hasDeliveryCoverage,
    } ],attrs:{"type":"button","data-test":"create-delivery"},on:{"click":function($event){$event.preventDefault();return _vm.createDelivery.apply(null, arguments)}}},[(_vm.isLoading)?_c('span',{staticClass:"delivery-partner__loading"}):[(_vm.displayDeliveryPartnerLabel)?_c('span',{staticClass:"delivery-partner__name"},[(_vm.logoPartner)?_c('img',{staticClass:"delivery-partner__logo",attrs:{"src":_vm.logoPartner,"alt":_vm.partner}}):_vm._e(),(_vm.isCtfCareem)?_c('span',[_vm._v(" "+_vm._s(_vm.$t("c.order.ctf_careem.request_driver"))+" "),_c('small',{staticClass:"delivery-partner__desc"},[_vm._v(" "+_vm._s(_vm.careemDesc)+" ")])]):_c('span',[_vm._v(_vm._s(_vm.deliveryPartner))])]):_vm._t("default")]],2)}
var staticRenderFns = []

export { render, staticRenderFns }