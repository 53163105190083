<template>
  <div class="actions-button">
    <div v-if="minorButton && !isScheduled">
      <ActionButton
        :label="minorButton.label"
        :class-name="minorButton.className"
        :action="minorButton.action"
        data-test="minor-button"
        @actionDispatched="actionDispatched"
      />
    </div>
    <div v-if="secondaryButton">
      <ActionButton
        :label="secondaryButton.label"
        :class-name="secondaryButton.className"
        :action="secondaryButton.action"
        data-test="secondary-button"
        @actionDispatched="actionDispatched"
      />
    </div>
    <div v-if="mainButton">
      <ActionButton
        :label="mainButton.label"
        :class-name="mainButton.className"
        :action="mainButton.action"
        class="btn btn-block text-uppercase f-size-13 bold p-2"
        data-test="main-button"
        @actionDispatched="actionDispatched"
      />
    </div>
  </div>
</template>

<script>
import ActionButton from "./ActionButton.vue";
import AcceptOrder from "./accept/Index.vue";
import RejectOrder from "./Reject.vue";
import { mapActions } from "vuex";
import { destroyDialog } from "@/utils/helpers/dialogs";
import { Channels, OrderingModes, Statuses } from "@/modules/order-card/enums";
import { orderCardTracking, OrderCardEventEnum } from "../tracking";
import { Toast } from "@/design-system";
import dayjs from "dayjs";

export default {
  name: "Actions",
  components: {
    ActionButton,
  },
  props: {
    preparationMinutes: {
      type: Number,
      required: true,
    },
    estimatedCompletionMinutes: {
      type: Number,
      required: true,
    },
    order: {
      type: Object,
      required: true,
    },
    deliveryServices: {
      type: Array,
      required: true,
    },
    serviceSettings: {
      type: Object,
      required: true,
    },
    printerSettings: {
      type: Object,
      required: false,
      default: null,
    },
  },
  data: () => ({
    isPrepTimeGone: false,
  }),
  computed: {
    isForPickup() {
      return this.order.ordering_service === OrderingModes.PICKUP;
    },
    isForDineIn() {
      return this.order.ordering_service === OrderingModes.DINE_IN;
    },
    isUberEats() {
      return this.order.channel === Channels.UBEREATS;
    },
    isAccepted() {
      return this.order.status === Statuses.ACCEPTED;
    },
    isScheduled() {
      return this.order.status === Statuses.SCHEDULED;
    },
    isDineInAccepted() {
      return this.isForDineIn && this.isAccepted;
    },
    acceptButton() {
      return {
        label: this.isScheduled
          ? this.$t("label.move_to_preparation")
          : this.$t("label.accept"),
        className: "btn btn-block btn-primary",
        action: "acceptOrder",
      };
    },
    scheduledButton() {
      return {
        label: this.$t("label.scheduled"),
        className: "btn btn-block btn-primary",
        action: "scheduleOrder",
      };
    },
    markAsReadyButton() {
      return {
        label: this.isForPickup
          ? this.$t("label.ready_to_collect")
          : this.$t("label.send_to_deliver"),
        className: "btn btn-block btn-primary",
        action: "markAsReady",
      };
    },
    completeButton() {
      return {
        label: this.$t("label.complete"),
        className: "btn btn-block btn-primary",
        action: "completeOrder",
      };
    },
    rejectButton() {
      return {
        label: this.$t("label.reject"),
        className: "btn btn-block btn-outline-danger",
        action: "rejectOrder",
      };
    },
    closeButton() {
      if (this.isAccepted && !this.isPrepTimeGone) {
        return null;
      }

      return {
        label: this.$t("label.close"),
        className: "btn btn-block btn-outline-dark",
        action: "closeOrder",
      };
    },
    cancelButton() {
      return {
        label: this.$t("label.cancel"),
        className: "btn btn-block btn-outline-danger",
        action: "cancelOrder",
      };
    },
    mainButton() {
      if (this.isUberEats) {
        return this.closeButton;
      }

      if (this.isDineInAccepted) {
        return this.completeButton;
      }

      if (this.order.scheduled_to !== null && this.order.status === "placed") {
        return this.scheduledButton;
      }

      switch (this.order.status) {
        case "placed":
          return this.acceptButton;
        case "scheduled":
          return this.acceptButton;
        case "accepted":
          return this.markAsReadyButton;
        case "ready":
          return this.completeButton;
        default:
          return null;
      }
    },
    secondaryButton() {
      if (this.isUberEats) {
        return null;
      }

      switch (this.order.status) {
        case "accepted":
          return this.cancelButton;
        case "scheduled":
          return this.cancelButton;
        case "ready":
          return this.cancelButton;
        default:
          return null;
      }
    },
    minorButton() {
      if (this.isUberEats || this.isDineInAccepted) {
        return null;
      }

      switch (this.order.status) {
        case "placed":
          return this.rejectButton;
        case "scheduled":
          return this.rejectButton;
        case "accepted":
          return this.closeButton;
        case "ready":
          return this.closeButton;
        default:
          return null;
      }
    },
  },
  mounted() {
    this.handleCloseButton();
  },
  methods: {
    ...mapActions({
      setAsClose: "orderCard/setAsClose",
      setAsCancel: "orderCard/setAsCancel",
      setAsComplete: "orderCard/setAsComplete",
      setAsReady: "orderCard/setAsReady",
      setAsScheduled: "orderCard/setAsScheduled",
    }),
    handleCloseButton() {
      const preparationEstimatedAt = dayjs(this.order.preparation_estimated_at);
      const now = dayjs();

      const msToGetPrepared = preparationEstimatedAt.diff(now, "millisecond");
      if (msToGetPrepared < 0) {
        this.isPrepTimeGone = true;
        return;
      }

      setTimeout(() => (this.isPrepTimeGone = true), msToGetPrepared + 1000);
    },
    openModal(component, params = {}) {
      this.$modal.show(component, params, {
        height: "auto",
        scrollable: true,
        clickToClose: false,
        adaptive: true,
        resizable: true,
      });
    },
    actionDispatched(action) {
      this[action]();
    },
    acceptOrder() {
      this.openModal(AcceptOrder, {
        order: this.order,
        deliveryServices: this.deliveryServices,
        printerSettings: this.printerSettings,
        serviceSettings: this.serviceSettings,
      });
    },
    rejectOrder() {
      this.openModal(RejectOrder, {
        order: this.order,
        orderId: this.order.id,
        orderUid: this.order.uid,
      });
    },
    closeOrder() {
      this.performAction("setAsClose");
    },
    scheduleOrder() {
      this.setAsScheduled({
        preparationMinutes: this.preparationMinutes,
        estimatedCompletionMinutes: this.estimatedCompletionMinutes,
      });
    },
    cancelOrder() {
      const options = {
        cancelLabel: this.$t("label.no"),
        confirmLabel: this.$t("label.yes_lets_cancel"),
        customMessage:
          this.order.payment_method === "card_online"
            ? this.$t("message.cancel_order_automatic_refund_x", {
                uid: this.order.uid,
              })
            : this.$t("message.cancel_order_x", { uid: this.order.uid }),
      };

      const callback = () => {
        this.$modal.hide("dialog");
        this.performAction("setAsCancel");
        orderCardTracking(this, OrderCardEventEnum.CANCLED, this.order);
      };

      destroyDialog(this, callback, options);
    },
    completeOrder() {
      this.performAction("setAsComplete");
      orderCardTracking(this, OrderCardEventEnum.COMPLETED, this.order);
    },
    markAsReady() {
      this.performAction("setAsReady");
      orderCardTracking(this, OrderCardEventEnum.READY, this.order);
    },
    async performAction(action) {
      try {
        await this[action]();
      } catch (err) {
        new Toast().create({
          type: "error",
          text: this.getErrorMessage(err),
        });
      }
    },
    getErrorMessage(validationError) {
      const businessRuleError = validationError?.error?.code;

      const knownRules = [
        "order_ready_minimum_threshold_not_met",
        "order_state_transition_minimum_threshold_not_met",
      ];

      if (!businessRuleError || !knownRules.includes(businessRuleError)) {
        return this.$t("message.something_went_wrong");
      }

      const minimumThreshold =
        validationError?.error?.context?.minimum_threshold;

      return this.$tc(`business_rules.${businessRuleError}`, minimumThreshold, [
        minimumThreshold,
      ]);
    },
  },
};
</script>

<style lang="scss" scoped>
.actions-button {
  display: flex;
  padding: 4px 2px;
  border-top: 1px solid #e8e9ea;

  > div {
    flex: 1 0 auto;
    padding: 0 2px;

    &:last-child {
      min-width: 50%;
    }
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    line-height: 100%;
  }
}
</style>
