interface IAddressIntoGMapLink {
  place?: string;
  location?: string;
  lat?: number;
  lng?: number;
  areaId?: string;
}

export const addressIntoGMapLink = ({
  place,
  location,
  lat,
  lng,
}: IAddressIntoGMapLink): string => {
  const link = "https://www.google.com/maps/search/?api=1";

  if (place) {
    const formattedPlace = place
      .replace(/\s+/g, " ")
      .trim()
      .split(" ")
      .join("+");

    return `${link}&query=${formattedPlace}`;
  }

  if (location) {
    return `${link}&query=${encodeURIComponent(location)}`;
  }

  if (lat && lng) {
    const coordinates = `${lat},${lng}`;
    return `${link}&query=${encodeURIComponent(coordinates)}`;
  }

  return link;
};
