import { mutationType as fetchType } from "@/store/fetchBase";
import { mutationType as resetType } from "@/store/resetBase";

/** @type {Object} Needed mutations to fetch the resource */
export const FETCH = fetchType;

/** @type {String} Mutation type responsible for resetting the module state */
export const RESET = resetType;

/** @type {String} Mutation type responsible for updating order status */
export const UPDATE_STATUS = "updateStatus";
