import { render, staticRenderFns } from "./RequestDelivery.vue?vue&type=template&id=4491334c&scoped=true&"
import script from "./RequestDelivery.vue?vue&type=script&lang=js&"
export * from "./RequestDelivery.vue?vue&type=script&lang=js&"
import style0 from "./RequestDelivery.vue?vue&type=style&index=0&id=4491334c&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4491334c",
  null
  
)

export default component.exports