<template>
  <a v-if="link" :href="link" target="_blank" rel="noopener noreferrer">
    <slot>{{ $t("label.learn_more") }}</slot>
  </a>
</template>

<script>
import axios from "axios";

export default {
  name: "ArticleLink",
  props: {
    tag: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    link: null,
  }),
  created() {
    this.fetch();
  },
  methods: {
    async fetch() {
      try {
        const response = await axios.get(process.env.VUE_APP_LINKS_SOURCE);

        this.link = response.data[this.tag];
      } catch (e) {
        //
      }
    },
  },
};
</script>
