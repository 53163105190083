import { has } from "ramda";

/**
 * Show default 'delete' dialog with confirm/cancel button.
 *
 * @param {Vue} vm Vue main instance
 * @param {Function} callback Callback to be exec if user confirm action
 * @param {{ resourceName: String, customMessage: String, confirmLabel: String, cancelLabel: String, title: String }} options to customize dialog
 * @returns {void}
 */
export const destroyDialog = (vm, callback, options = {}) => {
  const title = options.title || vm.$t("label.are_you_sure");

  const resourceName = options.resourceName || vm.$t("label.the_resource");

  const confirmLabel = options.confirmLabel || vm.$t("label.yes_delete_it");

  const cancelLabel = options.cancelLabel || vm.$t("label.cancel");

  const text = has("customMessage", options)
    ? options.customMessage
    : vm.$t("message.record_will_be_deleted", { record: resourceName });

  vm.$modal.show("dialog", {
    title,
    text,
    buttons: [
      {
        title: cancelLabel,
        default: true,
        class: "vue-dialog-button bg-light text-dark",
      },
      {
        title: confirmLabel,
        handler: callback,
        class: "vue-dialog-button bg-danger text-white",
      },
    ],
  });
};

/**
 * Show default 'confirm' dialog with confirm/cancel button.
 *
 * @param {Vue} vm Vue main instance
 * @param {Function} callback Callback to be exec if user confirm action
 * @param {{ title: String, customMessage: String, confirmLabel: String, cancelLabel: String }} options to customize dialog
 * @returns {void}
 */
export const confirmDialog = (vm, callback, options = {}) => {
  const title = options.title || vm.$t("label.are_you_sure");

  const confirmLabel = options.confirmLabel || vm.$t("label.yes");

  const cancelLabel = options.cancelLabel || vm.$t("label.cancel");

  const text = has("customMessage", options)
    ? options.customMessage
    : vm.$t("message.confirm_action_modal");

  vm.$modal.show("dialog", {
    title,
    text,
    buttons: [
      {
        title: cancelLabel,
        default: true,
        class: "vue-dialog-button bg-light text-dark",
      },
      {
        title: confirmLabel,
        handler: callback,
        class: "vue-dialog-button bg-primary text-white",
      },
    ],
  });
};
