<template>
  <div class="row mb-3">
    <div class="col-auto minw-55 text-right">{{ line.quantity }}x</div>
    <div class="col pl-0">{{ line.item.name }}</div>
    <div class="col-3 text-right">
      <Money
        tag="div"
        :amount="line.total"
        :business-id="businessId"
        :hide-symbol="true"
        class="col-3 text-right"
      />
    </div>
    <div
      v-for="modifier in line.modifiers"
      :key="modifier.id"
      class="col-11 ml-auto mt-1 text-muted"
    >
      {{ modifier.quantity }}x {{ modifier.modifier.name }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    line: {
      type: Object,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
  },
};
</script>
