import * as actions from "./actions";
import * as getters from "./getters";
import * as types from "./mutation-types";
import { clone, isNil, propOr } from "ramda";
import { mutation as resetMutation } from "@/store/resetBase";
import {
  mutation as fetchMutation,
  state as fetchState,
} from "@/store/fetchBase";

/** @type {Object} Initial state for the module */
export const initialState = {
  ...fetchState(),
};

export default {
  //Cloning the initial state of the module
  state: clone(initialState),
  mutations: {
    ...fetchMutation(types.FETCH),

    ...resetMutation(types.RESET, initialState),

    [types.UPDATE_STATUS](state, obj) {
      const orderId = propOr(null, "orderId", obj);
      if (!isNil(orderId) && orderId === state.data.id)
        state.data.status = obj.status;
    },
  },
  actions,
  getters,
  namespaced: true,
};
