import { isNil, propOr, prop } from "ramda";

/**
 * Returns if order is loaded based on the presence of ID property
 *
 * @returns {Boolean}
 */
export const isLoaded = ({ data }) => !isNil(propOr(null, "id", data));

/**
 * Return if module is currently in loading state
 *
 * @returns {Boolean}
 */
export const isLoading = prop("loading");

/**
 * Get the order data
 *
 * @returns {Object}
 */
export const getData = propOr({}, "data");

/**
 * Get the order status
 *
 * @returns {String}
 */
export const getStatus = ({ data }) => prop("status", data);

/**
 * Get the order uid
 *
 * @returns {String}
 */
export const getUid = ({ data }) => prop("uid", data);

/**
 * Get the order ordering service
 *
 * @returns {String}
 */
export const getOrderingService = ({ data }) => prop("ordering_service", data);

/**
 * Get the order created at
 *
 * @returns {String}
 */
export const getCreatedAt = ({ data }) => prop("created_at", data);

/**
 * Get the order id
 *
 * @returns {String}
 */
export const getId = ({ data }) => prop("id", data);

/**
 * Get the order status
 *
 * @returns {Object}
 */
export const getDelivery = ({ data }) => prop("delivery", data);
