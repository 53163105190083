
































































































































































































































import DeliveryJob from "./delivery/DeliveryJob.vue";
import RequestDelivery from "./delivery/RequestDelivery.vue";
import TalabatBadge from "./channels/TalabatBadge.vue";
import UberEatsBadge from "./channels/UberEatsBadge.vue";
import ZomatoBadge from "./channels/ZomatoBadge.vue";
import ZomatoGo from "./delivery/ZomatoGo.vue";
import {
  defineComponent,
  computed,
  getCurrentInstance,
  PropType,
  ref,
} from "@vue/composition-api";
import { AtomIcon } from "@/v2/design-system";
import { AtomIcon as AtomIcons } from "@/v2/new-design-system";
import {
  Channels,
  DeliveryPartners,
  PaymentMethods,
  OrderingModes,
  Statuses,
  DeliveryJobState,
} from "@/modules/order-card/enums";
import { translate } from "@/i18n";
// @ts-ignore
import { date, toCurrency } from "@/utils/filters";
import MolClipboardCopy from "@/v2/new-design-system/mol/clipboard-copy/ClipboardCopy.vue";
import { VTooltip } from "v-tooltip";
import { addressIntoGMapLink } from "@/v2/util/maps/address-into-gmap-link";
import { EventCategoryEnum, EventEnum } from "@/v2/enum";
import { useHelpers } from "@/v2/composable/use-helpers";
import { getDeliveryPartnersList } from "@/modules/order-card/utils";
import { BusinessTypeEnum } from "@/v2/repo/business-settings/business_type.enum";

export default defineComponent({
  name: "Details",
  filters: {
    date,
  },
  directives: {
    tooltip: VTooltip,
  },
  components: {
    AtomIcon,
    AtomIcons,
    DeliveryJob,
    RequestDelivery,
    TalabatBadge,
    UberEatsBadge,
    ZomatoBadge,
    ZomatoGo,
    MolClipboardCopy,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    customer: {
      type: Object,
      required: true,
    },
    outletName: {
      type: String,
      required: true,
    },
    businessId: {
      type: String,
      required: true,
    },
    businessName: {
      type: String,
      required: true,
    },
    businessCategory: {
      type: String,
      required: true,
    },
    delivery: {
      type: Object,
      required: false,
      default: null,
    },
    spot: {
      type: String,
      default: null,
    },
    deliveryServices: {
      type: Array as PropType<Array<DeliveryPartners>>,
      required: true,
    },
  },
  setup(props) {
    const deliveryLoadingState = ref(false);
    const isForDelivery = computed((): Boolean => {
      return props.order.ordering_service === OrderingModes.DELIVERY;
    });

    const isForPickup = computed((): Boolean => {
      return props.order.ordering_service === OrderingModes.PICKUP;
    });

    const isForDineIn = computed((): Boolean => {
      return props.order.ordering_service === OrderingModes.DINE_IN;
    });

    const iconOrderingService = computed((): String => {
      if (isForDelivery.value) {
        return "motorcycle";
      }

      if (isForPickup.value) {
        return "shopping-basket";
      }

      if (isForDineIn.value) {
        return "dine-in";
      }

      return "";
    });

    const channelLabel = computed(() => {
      const label = props.order.channel;
      return isForDineIn.value &&
        (props.businessCategory === BusinessTypeEnum.CINEMA ||
          props.businessCategory === BusinessTypeEnum.ROOM)
        ? label?.replace("table-", "")
        : label;
    });

    const isZomato = computed((): Boolean => {
      return props.order.channel === Channels.ZOMATO;
    });

    const isZomatoGo = computed((): Boolean => {
      return isZomato.value && !props.order.is_delivery_enabled;
    });

    const isUberEats = computed((): Boolean => {
      return props.order.channel === Channels.UBEREATS;
    });

    const isTalabat = computed((): Boolean => {
      return props.order.channel === Channels.TALABAT;
    });

    const hasDeliveryPartner = computed(() => {
      return (
        isForDelivery.value &&
        (props.delivery || isZomatoGo.value || isUberEats.value)
      );
    });

    const customerPhoneNumber = computed(() => {
      if (!isGuest) {
        return `+${props.customer.country_code}${props.customer.phone_number}`;
      }

      return props.order.customer_phone_number ?? undefined;
    });

    const isGuest = computed(() => {
      return !props.customer.phone_number;
    });

    const hasRegisteredDeliveryPartner = computed((): Boolean => {
      const partners = Object.values(DeliveryPartners);

      return partners.some((partner) =>
        props.deliveryServices.includes(partner)
      );
    });

    const canRequestDelivery = computed((): Boolean => {
      return (
        props.order.status === Statuses.ACCEPTED &&
        props.order.is_delivery_enabled &&
        hasRegisteredDeliveryPartner.value &&
        isForDelivery.value &&
        (!props.delivery ||
          [DeliveryJobState.FAILED, DeliveryJobState.CANCELLED].includes(
            props.delivery?.state
          ))
      );
    });

    const isNewCustomer = computed((): Boolean => {
      return props.customer.orders_count == 0;
    });

    const isOnlinePayment = computed((): Boolean => {
      return (
        props.order.payment_method === PaymentMethods.CARD_ONLINE ||
        props.order.payment_method === PaymentMethods.APPLE_PAY
      );
    });

    const paymentMethod = computed(() => {
      const method = props.order.payment_method;
      const service = !isForDineIn.value
        ? props.order.ordering_service
        : "dine_in";

      return translate(`label.payment_method_${method}_${service}`);
    });

    const addressLink = computed(() => {
      const { lat, lng } = props.order.address || {};
      if (lat && lng) {
        return addressIntoGMapLink({
          lat,
          lng,
        });
      }
      return null;
    });

    const orderCardToText = computed(() => {
      const business = useHelpers().getCurrentBusiness(props.businessId);
      return `${translate("module.live_orders.text_order_card.share_text")}:

*${translate("module.live_orders.text_order_card.order_id")}:* ${
        props.order.uid
      }
*${translate("module.live_orders.text_order_card.business")}:* ${
        props.businessName
      }
*${translate("module.live_orders.text_order_card.outlet")}:* ${props.outletName}
*${translate("module.live_orders.text_order_card.customer_name")}:* ${
        props.customer.name
      }‎
*${translate("module.live_orders.text_order_card.customer_phone")}:* +${
        props.customer.country_code
      }${props.customer.phone_number}
*${translate("module.live_orders.text_order_card.payment_type")}:* ${
        paymentMethod.value
      }‎
*${translate("module.live_orders.text_order_card.delivery_address")}:* ${`${
        props.order.address.number || ""
      } - ${props.order.address.area ? props.order.address.area.name : ""} - ${
        props.order.address.location
      } - ${props.order.address.zip_code || ""}`}
${
  props.order.address.instruction
    ? `*${translate(
        "module.live_orders.text_order_card.delivery_instructions"
      )}:* ${props.order.address.instruction}
`
    : ""
}${addressLink.value ? `*Map:* ${addressLink.value}` : ""}
--------
*${translate("module.live_orders.text_order_card.total")}:* ${toCurrency(
        props.order.total,
        {
          format: "$0,0",
          code: business.currency.code,
          locale: business.language,
        }
      )}`;
    });

    const deliveryPartnerList = computed(() => {
      return getDeliveryPartnersList(
        props.deliveryServices,
        props.order.payment_method
      );
    });

    const hasCtfCareem = computed(() => {
      return deliveryPartnerList.value.includes(DeliveryPartners.CAREEM);
    });

    const otherServices = computed(() => {
      return deliveryPartnerList.value.filter((service) => {
        return ![DeliveryPartners.CAREEM].includes(service);
      });
    });

    const currentInstance = getCurrentInstance();

    function trackEvent(action: string) {
      // @ts-ignore
      currentInstance?.proxy.$analytics.trackEvent(EventEnum.SHARE_ADDRESS, {
        event_category: EventCategoryEnum.LIVE_ORDERS,
        business_id: props.businessId,
        business_name: props.businessName,
        outlet_name: props.outletName,
        action,
      });
    }

    const setDeliveryLoadingState = (state: boolean) => {
      deliveryLoadingState.value = state;
    };

    return {
      isForDelivery,
      isForPickup,
      isForDineIn,
      iconOrderingService,
      orderCardToText,
      isZomato,
      isZomatoGo,
      isUberEats,
      isTalabat,
      hasDeliveryPartner,
      hasRegisteredDeliveryPartner,
      canRequestDelivery,
      isNewCustomer,
      isOnlinePayment,
      paymentMethod,
      addressLink,
      deliveryPartnerList,
      deliveryLoadingState,
      channelLabel,
      trackEvent,
      hasCtfCareem,
      otherServices,
      setDeliveryLoadingState,
      customerPhoneNumber,
      isGuest,
    };
  },
});
