<template>
  <div
    class="alert alert-zomato mt-1 mb-4 clearfix p-3 d-flex align-items-center"
  >
    <div>
      <ZomatoSVG class="w-40 mr-3" />
    </div>
    <div class="row w-100">
      <div class="col-md">
        <h6 class="text-dark bold my-1">
          {{ $t("label.delivery_assigned_to_x", { partner: "Zomato Go" }) }}
        </h6>
      </div>
      <div class="col-md-auto">
        <strong class="d-block text-zomato f-w-500 f-size-12 my-1">
          {{ $t("label.call_x_helpline_at", { partner: "Zomato" }) }}
          +971 4 550 9227
        </strong>
      </div>
    </div>
  </div>
</template>

<script>
import ZomatoSVG from "@/assets/svg/icons/zomato.svg";

export default {
  name: "ZomatoGo",
  components: {
    ZomatoSVG,
  },
};
</script>

<style scoped lang="scss">
$zomatoBrand: #ab202d;

.text-zomato {
  color: $zomatoBrand;
}

.alert-zomato {
  border: solid 1px rgba($zomatoBrand, 0.6);
  background: rgba($zomatoBrand, 0.15);
}
</style>
