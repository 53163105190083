import * as types from "./mutation-types";
import OrderService from "@/api/orders";
import { action as fetchAction } from "@/store/fetchBase";
import { action as resetAction } from "@/store/resetBase";
import { defaultIncludes, statuses } from "@/utils/enums/order";

/**
 * Fetch an order and track the analytics event ORDER_OPENED.
 *
 * @params {String} orderId
 * @returns {void}
 */
export const fetch = async ({ commit }, orderId) => {
  await fetchAction(commit, types.FETCH, OrderService.find, orderId, {
    include: defaultIncludes.values().join(","),
  });
};

/**
 * Reset the module to the initial state.
 *
 * @returns {Void}
 */
export const reset = ({ commit }) => resetAction(commit, types.RESET);

/**
 * Close the order.
 *
 * @returns {void}
 */
export const setAsClose = async ({ commit, getters }) => {
  const orderId = getters.getId;
  commit(types.FETCH.PENDING);
  await OrderService.close(orderId);
  commit(types.FETCH.DONE);
  commit(types.UPDATE_STATUS, { status: statuses.complete, orderId: orderId });
};

/**
 * Accepted the order.
 *
 * @param {Object} params preparation and completion time
 * @returns {void}
 */
export const acceptOrder = async ({ commit, getters }, params) => {
  const orderId = getters.getId;
  await OrderService.accept(orderId, params);
  commit(types.UPDATE_STATUS, { status: statuses.accepted, orderId: orderId });
};

/**
 * Reject the order.
 *
 * @param {String} reason reason to reject the order
 * @returns {void}
 */
export const rejectOrder = async ({ commit, getters }, reason) => {
  const orderId = getters.getId;
  await OrderService.reject(orderId, reason);
  commit(types.UPDATE_STATUS, { status: statuses.rejected, orderId: orderId });
};

/**
 * Cancel the order.
 *
 * @returns {void}
 */
export const setAsCancel = async ({ commit, getters }) => {
  const orderId = getters.getId;
  commit(types.FETCH.PENDING);
  await OrderService.cancel(orderId);
  commit(types.FETCH.DONE);
  commit(types.UPDATE_STATUS, { status: statuses.cancelled, orderId: orderId });
};

/**
 * Schedule the order.
 *
 * @returns {void}
 */
export const setAsScheduled = async ({ commit, getters }, setAsScheduled) => {
  const orderId = getters.getId;
  commit(types.FETCH.PENDING);
  await OrderService.schedule(orderId, setAsScheduled);
  commit(types.FETCH.DONE);
  commit(types.UPDATE_STATUS, { status: statuses.scheduled, orderId: orderId });
};

/**
 * Complete the order.
 *
 * @returns {void}
 */
export const setAsComplete = async ({ commit, getters }) => {
  try {
    const orderId = getters.getId;
    commit(types.FETCH.PENDING);
    await OrderService.complete(orderId);
    commit(types.FETCH.DONE);
    commit(types.UPDATE_STATUS, {
      status: statuses.complete,
      orderId: orderId,
    });
    // eslint-disable-next-line no-useless-catch
  } catch (e) {
    throw e;
  } finally {
    commit(types.FETCH.DONE);
  }
};

/**
 * Mark order as ready.
 *
 * @returns {void}
 */
export const setAsReady = async ({ commit, getters }) => {
  try {
    const orderId = getters.getId;
    commit(types.FETCH.PENDING);
    await OrderService.markReady(orderId);
    commit(types.UPDATE_STATUS, { status: statuses.ready, orderId: orderId });
    // eslint-disable-next-line no-useless-catch
  } catch (e) {
    throw e;
  } finally {
    commit(types.FETCH.DONE);
  }
};
