<template>
  <span class="orderCard__details--talabat badge badge-lg">
    <img src="@/assets/img/logo-talabat.png" />
  </span>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
.orderCard__details--talabat {
  width: 56px;
  border-radius: 3px;
  background: #ff6f00;
  padding: 2px 0;

  img {
    max-width: 53px;
  }
}
</style>
