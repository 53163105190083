





















































import { bemBuilder } from "@/v2/util/bem-builder";
import { defineComponent, PropType, ref, computed } from "@vue/composition-api";
import DeliveryPartners from "./DeliveryPartners.vue";
import { DeliveryPartners as PartnersEnum } from "@/modules/order-card/enums";
import { getDeliveryPartnersList } from "@/modules/order-card/utils";
import { t } from "@/i18n";

const css = bemBuilder("delivery");

export default defineComponent({
  name: "Delivery",
  components: {
    DeliveryPartners,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
    deliveryServices: {
      type: Array as PropType<Array<PartnersEnum>>,
      default: () => [],
    },
  },
  setup(props) {
    const showDeliveryPartners = ref(false);

    const partners = computed(() => {
      return getDeliveryPartnersList(
        props.deliveryServices,
        props.order.payment_method
      );
    });

    const hasCtfCareem = computed(() => {
      return partners.value.includes(PartnersEnum.CAREEM);
    });

    const otherServices = computed(() => {
      return partners.value.filter((service) => {
        return ![PartnersEnum.CAREEM].includes(service);
      });
    });

    return {
      t,
      css,
      partners,
      hasCtfCareem,
      otherServices,
      showDeliveryPartners,
    };
  },
});
